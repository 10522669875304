body.dragging,
body.dragging * {
  cursor: grabbing !important;
}

.note-length-badge {
  position: relative;
  height: 18px;
  min-width: 18px;
  top: -10px;
  line-height: 18px;
  padding: 0 !important;
  margin-left: -10px !important;
  margin-right: -4px !important;
  background-color: #fff2ab !important;
  color: black !important;
}
.notes-add {
  padding: 2px;
  margin-right: 16px;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    color: lightgrey;
  }
}

.note-toolbar-button.dropdown {
  display: flex;
  flex-direction: column;
  .btn.dropdown-toggle {
    padding: 1px;
    border: none;
  }
}

.pointer {
  cursor: pointer;
}

.note-highlight {
  display: block;
  box-shadow: 0 0 0px 4px #fff2ab !important;
}

.notes-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 240px);
  .badge.badge-info,
  .badge.badge-success {
    font-size: 14px;
    font-weight: normal;
    padding: 2px 4px;
  }
  .note-value {
    white-space: pre;
    position: relative;
    .x {
      display: none;
    }
    &:hover {
      .x {
        display: block;
        position: absolute;
        background-color: #17a2b8;
        right: 0;
        top: 0;
      }
    }
  }
  [role='toolbar'] {
    top: 0;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
  .element-information {
    border-radius: 4px;
    background-color: #41515e;
    margin: 8px;
    p {
      color: white;
      margin: 8px;
    }
    // p:first-child {
    //   margin-top: 0;
    // }
    p:last-child {
      margin-bottom: 0;
    }
  }
  .search-bar {
    margin: 8px;
    height: unset !important;
  }
  .notes {
    margin: 8px;
    overflow: auto;
    margin-right: 0px;
    padding-right: 8px;
    flex-grow: 1;
    .note-overlay-fullscreen.fullscreen {
      position: fixed;
      z-index: 50;
      inset: 0;
    }
    .note {
      margin: 8px auto;
      height: auto;
      position: relative;
      .dropdown-menu {
        min-width: 100px;
        max-height: 400px;
        padding: 0;
        .dropdown-item {
          font-size: 14px;
          padding: 2px 12px;
        }
      }
      &.closed {
        .note-container {
          max-height: 180px;
          overflow: hidden;
        }
      }
      .element-information-note {
        padding: 8px;
        background-color: white;
        border-bottom: 1px solid lightgray;
        p {
          color: black;
          margin: 8px;
        }
        p:first-child {
          margin-top: 0;
        }
        p:last-child {
          margin-bottom: 0;
        }
      }
      .note-resizer {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: nwse-resize;
        line-height: 0px;
      }
      .center-vertical {
        display: flex;
        flex-direction: row;
        align-items: center;
        .note-value:first-child {
          margin: 0 4px 0 8px;
        }
        .note-value {
          margin: 0 4px;
        }
        .note-value:last-child {
          margin: 0 8px 0 4px;
        }
      }
      .note-toolbar {
        display: flex;
        flex-direction: row;
        height: auto;
        align-items: center;
        padding-left: 4px;
        background-color: #fff2ab !important;

        .note-toolbar-button:first-child {
          margin: 0 2px 0 4px;
        }
        .note-toolbar-button {
          margin: 0 2px;
        }
        .note-toolbar-button:last-child {
          margin: 0 4px 0 2px;
        }

        .no-padding {
          padding: 0 !important;
        }
        .note-header {
          display: inline;
          font-weight: bold;
          margin-left: 12px;
        }
        // * {
        //   background-color: #fff2ab !important;
        // }
        .note-toolbar-button {
          padding: 2px;
          cursor: pointer;
        }
        .note-dragger {
          cursor: move;
        }
      }
      .note-container {
        background-color: #fff7d1;
        position: relative;
        overflow: auto;
        .bg-background.ring-offset-background {
          background-color: #fff7d1;
          min-height: unset;
        }
      }
      .note-container,
      .note-container *:not(textarea) {
        border: none !important;
        --tw-ring-color: transparent !important;
        outline: none !important;
        box-shadow: none !important;
      }
      &.closed {
        // height: 100px;
        .mdxeditor-toolbar {
          display: none !important;
        }
      }
      // &:not(.fullscreen) {
      .note-toolbar {
        justify-content: space-between;
      }
      // }
      &:not(.fullscreen).highlight .note-toolbar {
        border-left: 3px solid #17a2b8;
      }
      &.open {
        display: flex;
        flex-direction: column;
        // min-height: 300px;
        width: 100%;
        &.fullscreen {
          border: 2px solid #fff2ab;
          .note-toolbar {
            justify-content: space-between;
            cursor: grab;
          }
          position: fixed;
          z-index: 50;
          display: flex;
          inset: 200px;
          width: auto;
          max-height: 100vh;
          max-width: 100vw;
        }
        .mdxeditor {
          width: 100%;
          flex-grow: 1;
          .mdxeditor-toolbar {
            background-color: #fff2ab !important;
            * {
              background-color: #fff2ab !important;
            }
          }
        }
      }
      .markdown.w-md-editor-fullscreen {
        inset: 200px;
        height: auto !important;
      }
      .markdown-preview {
        padding: 24px;
      }
    }
  }
}

.shimmer:after {
  content: ' ';
  background: linear-gradient(to right, #ffffffa1 8%, #ffffff40 18%, #ffffffa1 33%);
  background-size: 1000px 100%;
  animation: shimmer 2.2s linear infinite forwards;
  position: absolute;
  inset: 8px;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
