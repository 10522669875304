@import './colors.scss';

a {
  text-decoration: none !important;
}
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
  border-radius: 50%;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #{nth($blue-grey, 3)};
}

hr {
  border: 1px solid #ccc !important;
}

//.xt-panel {
//  flex: 1 1 50%;
//}
//
.xt-panel.fillvert {
  flex: 1 1 100%;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tooltip.custom {
  opacity: 1;
  z-index: 99999;
  .arrow {
    width: 0 !important;
    height: 0 !important;
  }
  .arrow:before {
    content: '';
    border-width: 0 !important;
  }
  .tooltip-inner {
    padding: 8px 24px;
    //transform: translateX(15px);
    background-color: #{nth($light-teal, 3)};
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #{nth($blue-grey, 1)};
  }
}
.tooltip.custom.light {
  padding: 0;
  .tooltip-inner {
    padding: 4px 12px;
    font-size: 10px;
  }
}

body.resizing .desktop .window.active .work-area:after {
  position: absolute;
  content: ' ';
  height: 100%;
  width: 100%;
  inset: 0 0 0 0;
}

.desktop {
  overflow-y: hidden;
  height: calc(100vh - 52px);
  background: var(--background-color);
  .window-switcher {
    height: 48px;
    border-bottom: 1px solid #{nth($blue-grey, 5)};
    display: flex;
    background-color: var(--tab-header);

    * {
      user-select: none;
    }

    .switcher-item {
      color: var(--tabs-top-color);
      display: inline-block;
      height: 48px;
      font-size: 11px;
      line-height: 48px;
      padding: 0 18px;
      cursor: pointer;
      font-weight: 600;
      text-transform: uppercase;

      &.editing {
        padding: 4px 20px;
      }
      .tabs-editor {
        width: 100%;
      }
    }

    .switcher-item.active {
      border-bottom: 4px solid #{nth($teal, 3)};
    }
  }

  .windows {
    height: calc(100vh - 100px);
    overflow-y: auto;
    margin: 0;

    //&.dashboard {
    //  padding: 0;
    //}

    background-color: var(--background-color);

    .button-bar {
      background: var(--background-footer);
      position: fixed;
      bottom: 0;
      left: 0px;
      right: 0px;
      height: auto;
      padding: 8px 8px;
      z-index: 2;

      .col-md-2 {
        margin-top: 8px;
      }
    }

    .window {
      display: none;
      visibility: hidden;
      left: -9999px;
      width: 100%;
      height: 100%;
      position: absolute;
      padding-right: 76px;

      &.no-toolbar {
        padding-right: 0;
      }

      .button-bar .col-md-auto {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      .area-container {
        height: 100%;
      }
    }

    @for $i from 0 through 3 {
      .button-rows-#{$i} {
        height: calc(100vh - 150px - #{$i * 40px});
      }
    }
    .work-plus-context-area {
      display: flex;
      flex-direction: row;
      //overflow: auto;
    }

    .work-area {
      position: relative;
      display: block;
      flex: 1 1 80%;
      .panel-area {
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: calc(100vh - 192px);
      }
    }

    .context-area {
      position: relative;
      display: flex;
      //width: 400px;
      min-width: 400px;
      max-width: 80vw;
      flex-direction: column;
      overflow: auto;
      border-left: 1px solid #6d899d;
      flex: 0 0 20%;

      &[data-resizing='true']:after {
        position: absolute;
        content: ' ';
        height: 100%;
        width: 100%;
        inset: 0 0 0 0;
      }

      .resizer {
        top: 0 !important;
        position: absolute;
        height: 100%;
        width: 2px;
        cursor: col-resize;
      }
    }

    .window.active {
      display: block;
      visibility: visible;
      position: relative;
      left: auto;
    }

    h3 {
      font-size: 18px;
    }
    table {
      font-size: 14px;
    }
  }

  .xt-group {
    height: 100%;
    width: 100%;
    position: relative;

    &.grid {
      height: auto;
      display: flex;
      flex-wrap: wrap;

      & > * {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }

    &.has-title {
      margin-top: 12px;
      border: solid 1px var(--text-color);
      border-radius: 4px;
      overflow: visible;
      height: calc(100% - 26px);
      padding-top: 12px;
    }

    .heading {
      position: absolute;
      top: -8px;
      left: 12px;
      background-color: var(--search-fields-bg);
      color: var(--search-fields-text);
      z-index: 2;
      line-height: 20px;
    }
  }

  .tab-switcher ~ .xt-group {
    height: calc(100% - 50px);
  }

  .panel-actions {
    z-index: 11;
    position: fixed;
    right: 80px;
    transform: translateY(10px);
    span {
      cursor: pointer;
      padding: 10px 0;
      margin: 0 8px;
      &.active {
        border-bottom: 4px solid #00a3a5;
      }
    }
  }
  .panel-actions-placeholder {
    height: 52px;
    border: {
      //left: 1px solid #6d899d;
      bottom: 1px solid #6d899d;
    }
  }
  .panel-container {
    border: {
      //left: 1px solid #6d899d;
      bottom: 1px solid #6d899d;
      top: 1px solid #6d899d;
    }
    margin-top: 44px;
    padding: 10px;
    .context-close {
      cursor: pointer;
    }
    h6 {
      margin: 0;
      color: var(--text-color);
    }
    .panel-close {
      float: right;
      padding: 2px;
      color: var(--text-color);
    }
  }
  iframe {
    border: none;
    flex: 1 1 100%;
  }
  .function-loader > button {
    &:hover .more-button path,
    &:focus .more-button path {
      fill: var(--dark-button-hover-text);
    }
    &::after {
      display: none;
    }
    & .more-button {
      content: '';
      display: block;
      background-repeat: no-repeat;
      background-size: 16px 16px;
      height: 16px;
      width: 16px;
      border: 0 !important;
      & path {
        fill: var(--btn-outline-dark-color);
      }
    }
  }
}

.panel-protection-enabled table {
  overflow: hidden !important;
}

.panel-protection-enabled:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.navbar-light {
  background-color: var(--background-header) !important;
  padding: 0.25rem 0.5rem;
}

.bg-white {
  //background-color: var(--background-header) !important;
  box-shadow: 0px 2px 8px rgba(65, 81, 94, 0.08);
}

.modal.window.active {
  .modal-body {
    min-height: 80vh;
    min-width: 40vw;
    background: var(--desktop-modal-f4);
    padding: 0;

    [data-window] {
      height: 100%;
      .work-plus-context-area {
        height: calc(100% - 60px);
        .custom-popup-modal {
          ol {
            padding: 1rem 2rem;
            background: var(--custom-breadcrumb-modal);
          }
        }
        .button-rows-1 {
          height: 100%;
          .panel-area {
            height: 85%;
            display: flex;
            flex-direction: column;
            overflow: auto;
            padding: 1rem;
          }
        }
      }
    }
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #{nth($teal, 3)};
}

#message-action .badge {
  position: absolute;
  top: 0;
  left: 10px;
  background-color: #{nth($primary, 3)};
  color: white;
}

.print-only {
  display: none;
  font-family: 'Courier New', monospace !important;
  width: fit-content;
  height: fit-content;
  white-space: pre;
  .page {
    font-family: 'Courier New', monospace !important;
  }
}

.control-parent {
  line-height: 24px;
}

.table-container {
  z-index: 1;
}

@media print {
  @page {
    size: auto;
  }
  ::-webkit-scrollbar {
    display: none !important;
  }
  body:not(.no-print) {
    * {
      visibility: hidden;
      overflow-y: visible !important;
    }
    .dashboard-container {
      * {
        visibility: visible !important;
      }
      .react-page-row {
        width: 100% !important;
        .react-page-cell {
          break-inside: avoid;
          width: 100% !important;
        }
      }
    }
    .work-area {
      visibility: visible !important;
      * {
        visibility: visible !important;
      }
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      height: 100% !important;
      width: 100% !important;
      .panel-area {
        height: calc(100% - 80px) !important;
      }
    }
  }
  body.no-print {
    * {
      display: none;
    }

    .print-only {
      display: block !important;
      span.page {
        display: block !important;
        break-after: page;
      }
    }
  }
}
