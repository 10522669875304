.header {
  padding: 10px 10px;
  background: #fff;
}

.label {
  width: 200px;
  text-align: right;
  margin-right: 10px;
}

.uidOutputOnly {
  width: 300px;
  padding-left: 2px;
  color: #7a7b7e;
}

.footer {
  background: #eff0f2;
  padding: 16px 10px;
  position: fixed;
  bottom: 0;
  width: 99%;
  button {
    padding: 5px 20px;
    border: 1px solid;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    background: transparent;
    margin-right: 10px;
  }
}

.boxes:before {
  content: '\2714';
  border: 1px solid #adb5bd;
  border-radius: 3px;
  display: inline-block;
  background: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  color: transparent;
}

.boxes:focus:checked:before {
  border: 2px solid #00a3a5;
}
.boxes:focus:before {
  border: 2px solid #00a3a5;
}

.boxes:checked:before {
  background: #d0eeee;
  border: 1px solid #00a3a5;
  color: #000;
}
