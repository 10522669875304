@import '/src/styles/colors.scss';

.to-do-wrapper {
  .add-task {
    padding-right: 15px;
    padding-top: 15px;
    .add-task-btn {
      display: flex;
      padding-left: 15px;
      cursor: pointer;
      .icon {
        margin-right: 10px;
        color: #{nth($teal, 3)};
        font-size: 30px;
        font-weight: 100;
      }
    }
    .task-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      background: #{nth($light-teal, 3)};
      outline: none;
      .add-btn {
        font-weight: 600;
        color: #{nth($teal, 3)};
        font-size: 14px;
        outline: none;
        border: none;
        padding: 0px 10px;
        background: transparent;
      }
    }
  }
  .to-do-list {
    padding-left: 15px;
    margin-top: 15px;
    overflow-x: hidden;
    .list-items {
      display: flex;
      align-items: center;
      .boxes:before {
        content: '\2714';
        border: 2px solid #{nth($teal, 3)};
        border-radius: 3px;
        display: inline-block;
        background: #fff;
        width: 15px;
        height: 15px;
        text-align: center;
        color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .boxes:checked:before {
        background: #{nth($light-teal, 3)};
        border: 2px solid #{nth($teal, 3)};
        color: #{nth($teal, 3)};
      }

      .task-name {
        margin-left: 10px;
        padding-bottom: 10px;
        padding-top: 10px;
        font-weight: 600;
      }
    }
  }
  .no-data-text {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
