@import 'colors';
.modal-nav-container {
  width: 100%;
  padding: 12px 24px;
  background: #eef0f2;
}

.modal-nav-container > div:last-child {
  margin-right: 0px;
}

.Setting-Content-container {
  width: 100%;
  padding: 25px 25px 32px 25px;
  .add-role {
    background: var(--table-header);
    color: var(--table-header-text-color);
  }
  .role-settings-button {
    float: right;
    height: 20px;
    button {
      border: 1px solid var(--btn-outline-dark-bg);
      color: var(--btn-outline-dark-color);
      background: transparent;
      &:hover {
        color: var(--dark-button-hover-text);
        background-color: var(--dark-button-hover-bg);
        border-color: var(--dark-button-hover);
        border-radius: 4px;
        box-shadow: none;
        &:after {
          color: var(--dark-button-hover-text);
        }
      }
      &:focus {
        color: #{nth($primary, 3)};
        background-color: transparent;
        border-color: #{nth($primary, 3)};
        border-radius: 4px;
        box-shadow: none;
        &:after {
          color: var(--dark-button-hover-text);
        }
      }
      &:after {
        vertical-align: 8px !important;
        color: black;
      }
    }
  }
}

.Locale-content-wrapper {
  width: 100%;
  margin-bottom: 15px;
}

.custom-toggle-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  .dark-header {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--text-color);
  }
  .dark-toggle {
    margin-left: 90px;
  }
}

.Locale-content-wrapper > div {
  display: inline-block;
  vertical-align: middle;
}

.Locale-content-wrapper > div:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--text-color);
}

.Locale-content-wrapper > div:last-child {
  margin-left: 122px;
}
.custom-dark-toggle > div:last-child {
  margin-left: 80px;
}

.Locale-content-wrapper > div:last-child > select {
  border: 1px solid #00a3a5;
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #2f3c46;
}

.settings-modal .modal-footer > button {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 35px;
  justify-content: flex-end;
}

//.modal-footer > button:first-child {
//  margin-right: 16px;
//}

.setting-row {
  > .col-md-6 {
    margin-top: 8px;
    &:nth-child(odd) {
      text-align: right;
    }
  }
}
.setting-row2 {
  > .col-md-6 {
    margin-top: 2px;
    &:nth-child(odd) {
      text-align: right;
    }
  }
}
select.settings-error {
  border: 2px solid red;
}
.local-language-icon {
  padding: 2px;
  position: absolute;
  bottom: 2%;
  right: 59%;
  cursor: pointer;
}
