@import './colors.scss';

.internal-tabs.tab-container {
  &:not(.empty) {
    height: 100%;
  }
  span[aria-selected='true'] {
    border-bottom: 4px solid var(--tab-border-bottom);
    color: var(--tab-header-text-color);
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0;
  }
  span[aria-selected='false'] {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--tab-header-text-color);
  }
  span.tab-heading {
    margin: 4px 16px;
    padding: 8px 0;
    white-space: nowrap;
  }
  span.tab-heading-pipe {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      border-left: 1px var(--tab-header-text-color) solid;
      top: 35%;
      right: 0;
      height: 20%;
    }
    &:last-child::after {
      display: none;
    }
  }
  span:hover {
    //color: #{nth($blue-grey, 1)};
  }
  .tab-switcher {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    padding-right: 40px;
  }
  .more-tabs > button {
    background: white !important;
    border-color: transparent !important;
    &:hover::after,
    &:focus::after {
      filter: invert(1);
    }
    &:after {
      content: '';
      display: block;
      background: url('../assets/function_loader.svg') no-repeat;
      background-size: 16px 16px;
      height: 16px;
      width: 16px;
      border: 0 !important;
    }
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  > .dropdown {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 36px;
    .btn-link {
      color: black;
      &:hover {
        color: black;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .dropdown-item {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--tab-dropdown-item-color);
    }
  }
}
