.export-container {
  position: fixed;
  z-index: 9998;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: transparent;
  //backdrop-filter: blur(2px);
  cursor: progress;
  .export-card {
    width: 50vw;
    top: 35%;
    margin: 0 auto;
  }
}
