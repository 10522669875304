@import '/src/styles/colors';

.file-upload-wrapper {
  padding-top: 20px;
  .select-folder {
    display: flex;
    align-items: center;
    .select-label {
      color: var(--text-color);
      font-weight: 600;
      text-align: center;
      margin-right: 5px;
    }
  }
  .select {
    margin: 4px;
    display: block;
    flex: 0 1 20%;
    outline: none;
    border: 1px solid #9c9c9c;
    border-radius: 4px;
  }
  .select:focus,
  .select:hover {
    outline: none;
    border: 1px solid #00a3a5;
    border-radius: 4px;
  }
  .status {
    background: #d0eeee;
    border-radius: 4px;
    padding: 5px;
    text-align: center;
    font-size: 12px;
  }
  .file-list {
    margin-left: 10px;
    padding: 10px 10px 10px 15px;
    overflow-x: auto;
    height: 31vh;
    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 60%;
      .item-btn {
        border: 1px solid #00a3a5;
        background-color: #d0eeee;
        color: #00a3a5;
        font-size: x-small;
        padding: 0px 5px;
        border-radius: 4px;
      }
    }
  }
  .footer {
    background: #eff0f2;
    padding: 16px 10px;
    position: fixed;
    bottom: 0;
    width: 99%;
    .btn {
      padding: 5px 20px;
      border: 1px solid;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      background: transparent;
      margin-right: 10px;
      &:hover,
      &:focus {
        color: var(--dark-button-hover-text);
        background-color: var(--dark-button-hover-bg);
        border-color: var(--dark-button-hover);
        border-radius: 4px;
        box-shadow: none;
      }
    }
  }
}
