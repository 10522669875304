@import './colors.scss';

.pointer {
  cursor: pointer;
}

.custom-radio .custom-control-label {
  white-space: pre;
  font-size: 12px;
  line-height: 24px;
  color: var(--text-color);
}

label.form-label {
  &:not(.text-left) {
    text-align: right;
  }

  &.AccountString {
    font-family: 'Courier New';
    text-align: left;
  }

  &.OutputOnly {
    text-align: left;
  }
  &.DynamicLabel {
    text-align: right;
  }
  &.LabelAboveCharacter {
    text-align: left;
  }
  &.LabelAboveNumeric {
    text-align: right;
  }
  &.LabelAboveDate {
    text-align: left;
  }
  &.LabelAboveTime {
    text-align: left;
  }
  &.DeleteConfirmLabel {
    text-align: left;
  }
  &.ConfirmLabel {
    text-align: left;
  }
  &.LabelAboveGroup {
    text-align: right;
  }
  &.MessageLabel {
    text-align: left;
  }
  &.LabelSection {
    text-align: left;
  }
  &.BufferString {
    text-align: right;
  }
  &.LabelAboveCombo {
    text-align: right;
  }
  &.LabelStandAlone {
    text-align: left;
  }

  &.LabelAboveGroup {
    font-weight: 600;
  }

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  color: var(--text-color);
  margin-bottom: 0;
  white-space: pre;
  &.monospace {
    color: var(--text-color);
    font-family: 'Courier New' !important;
    font-weight: 600;
  }
  &.dynamictableLabel {
    line-height: 20px;
  }
}

.monospace {
  font-family: 'Courier New' !important;
}
