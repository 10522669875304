@import 'colors';

.custom-radio {
  .custom-control-label::before {
    border-radius: 50%;
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: '';
    background-color: var(--radio-button-background-color);
    border: solid 1px var(--input-form-control-border);
  }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    background: 50%/50% 50% no-repeat;
  }
  .custom-control-input {
    &:focus {
      ~ .custom-control-label::before {
        border: 2px solid #{nth($primary, 3)}!important;
      }
    }
    .is-invalid {
      border: 1px solid $error !important;
    }
    &:checked {
      ~ .custom-control-label::before {
        //color: #{nth($grey, 7)};
        border: 1px solid var(--input-form-control-border);
        background-color: var(--radio-button-background-checked);
      }
      ~ .custom-control-label::after {
        content: '';
        width: 8px;
        height: 8px;
        background: var(--radio-button-dot);
        position: absolute;
        top: 8px;
        left: -20px;
        border-radius: 100%;
        transition: all 0.2s ease;
      }
    }
    &:disabled {
      ~ .custom-control-label::before {
        background-color: white;
        border-color: #{nth($grey, 3)};
      }
      &:checked {
        ~ .custom-control-label::before {
          background-color: white;
        }
        ~ .custom-control-label::after {
          background-color: #{nth($grey, 3)};
        }
      }
    }
    &:focus ~ .custom-control-label::before {
      box-shadow: none !important;
    }
  }
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #{nth($blue-grey, 1)};
}
