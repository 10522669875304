@import './colors.scss';

.select-view-modal {
  
  .select-view-item:hover {
    color: var(--table-row-not-selected-text-color);
    background-color: var(--table-row-not-selected-background);
    cursor: pointer;

    &.disabled {
      cursor: default;
    }
  }

  .select-view-item.selected,
  .select-view-item.selected:hover {
    background-color: #{nth($teal, 3)}!important;
    color: #{nth($grey, 7)};
  }

  .select-view-item.disabled,
  .select-view-item.disabled:hover {
    background-color: transparent;
    color: #ccc;
  }
}