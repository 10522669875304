@import 'colors';

.combo-text-style {
  background-color: #{nth($grey, 7)} !important;
  border: 1px solid #{nth($blue-grey, 3)} !important;

  &.is-invalid {
    border: 1px solid $error !important;
  }

  &:focus {
    border: 2px solid #{nth($primary, 3)} !important;
  }
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none !important;
  color: #{nth($grey, 1)} !important;
  &[aria-disabled='true'] {
    border: 1px solid #{nth($grey, 4)} !important;
  }
  &:read-only {
    background-color: #{nth($grey, 7)};
  }
}

.combo-text-font {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.dropdown-box {
  height: 24px;
  padding: 4px 2px;
}

.combo-placeholder-shown {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #{nth($grey, 3)};
}

.dropdown-list {
  margin-top: -3px;
  z-index: -1;
  max-height: 204px;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 0 16px rgba(65, 81, 94, 0.24);
}

.combo-icon-image {
  float: right;
  &[aria-disabled='true'] {
    path {
      stroke: #{nth($grey, 4)};
    }
  }
}

.selected-item {
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  white-space: pre-wrap;
  color: #{nth($blue-grey, 1)};
  &[aria-disabled='true'] {
    color: #{nth($grey, 4)};
  }
}

.empty-value {
  height: 24px;
}

.show {
  .full-width {
    .combo-icon-image {
      transform: rotate(180deg);
      &[aria-disabled='true'] {
        color: #{nth($grey, 4)};
        transform: rotate(0);
      }
    }
  }
}

.dropdown-list-item {
  // &:not(.ignore-height) {
  //   height: 24px;
  // }
  display: block;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding: 12px 16px 11px 16px;
  color: #{nth($blue-grey, 3)};
  &:hover {
    font-weight: 600;
    background-color: #{nth($light-teal, 3)};
  }
}

.full-width {
  width: 100%;
  text-align: left;
  &::after {
    content: none;
  }
}
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='top'] {
  z-index: 999;
  overflow: auto;
}

.dropdown-menu[x-placement^='bottom'][aria-labelledby='LoadedFunction'] {
  transform: translate3d(-100%, -100%, 0px) translateX(46px) !important;
  max-height: 80vh;
  overflow: auto;
}

.dropdown-box {
  height: 24px;
  padding: 1px 2px;
}
.combo-icon-image {
  width: 12px;
}
.dropdown-list-item {
  &:not(.ignore-height) {
    height: 30px;
  }
  padding: 4px;
}
.dropdown-menu {
  background-color: var(--dropdown-background);
}
.dropdown-item {
  color: var(--text-color);
}
