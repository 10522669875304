$teal: #008585, #009293, #00a3a5, #42b3b6, #7bc8cc;
$light-teal: #47b4b6, #91d3d3, #d0eeee, #e9f9f9, #f7ffff;
$blue-grey: #2f3c46, #41515e, #506574, #60798b, #6d899d, #eff0f2;
$pink: #de284a, #e44262, #eb617c, #f28da1, #f8bac6;
$grey: #121212, #525252, #cfcfcf, #e8e8e8, #f1f1f1, #f8f8f8, #ffffff;
$light-grey: #eef0f2;
$error: #ff0201;
$warning: #ffdd07;
$primary: $teal;
$primary-dark: $blue-grey;
$secondary: $pink;
$secondary-light: $light-teal;
$background: $light-grey;
$dark-mode: #394956, #495e6e, #5a7387, #2c3740, #374651, #90a4b6, #2a3741, #f3faff, #36414b, #525b63, #313c45;
$menu-box-shadow-color: #0000001f;
$menu-yellow: #f49433;
$menu-border: #16232c;
$menu-teal: #9edcdd, #99dadb, #007f81, #66c8c9, #026c6e;
$menu-search-output: #222f38;

.teal {
  &.darken-2 {
    background-color: #{nth($teal, 1)};
  }
  &.darken-1 {
    background-color: #{nth($teal, 2)};
  }
  & {
    background-color: #{nth($teal, 3)};
  }
  &.lighten-1 {
    background-color: #{nth($teal, 4)};
  }
  &.lighten-2 {
    background-color: #{nth($teal, 5)};
  }
}

.teal-text {
  &.darken-2 {
    color: #{nth($teal, 1)};
  }
  &.darken-1 {
    color: #{nth($teal, 2)};
  }
  & {
    color: #{nth($teal, 3)};
  }
  &.lighten-1 {
    color: #{nth($teal, 4)};
  }
  &.lighten-2 {
    color: #{nth($teal, 5)};
  }
}

.light-teal {
  &.darken-2 {
    background-color: #{nth($light-teal, 1)};
  }
  &.darken-1 {
    background-color: #{nth($light-teal, 2)};
  }
  & {
    background-color: #{nth($light-teal, 3)};
  }
  &.lighten-1 {
    background-color: #{nth($light-teal, 4)};
  }
  &.lighten-2 {
    background-color: #{nth($light-teal, 5)};
  }
}

.light-teal-text {
  &.darken-2 {
    color: #{nth($light-teal, 1)};
  }
  &.darken-1 {
    color: #{nth($light-teal, 2)};
  }
  & {
    color: #{nth($light-teal, 3)};
  }
  &.lighten-1 {
    color: #{nth($light-teal, 4)};
  }
  &.lighten-2 {
    color: #{nth($light-teal, 5)};
  }
}

.blue-grey {
  &.darken-2 {
    background-color: #{nth($blue-grey, 1)};
  }
  &.darken-1 {
    background-color: #{nth($blue-grey, 2)};
  }
  & {
    background-color: #{nth($blue-grey, 3)};
  }
  &.lighten-1 {
    background-color: #{nth($blue-grey, 4)};
  }
  &.lighten-2 {
    background-color: #{nth($blue-grey, 5)};
  }
}

.blue-grey-text {
  &.darken-2 {
    color: #{nth($blue-grey, 1)};
  }
  &.darken-1 {
    color: #{nth($blue-grey, 2)};
  }
  & {
    color: #{nth($blue-grey, 3)};
  }
  &.lighten-1 {
    color: #{nth($blue-grey, 4)};
  }
  &.lighten-2 {
    color: #{nth($blue-grey, 5)};
  }
}

.pink {
  &.darken-2 {
    background-color: #{nth($pink, 1)};
  }
  &.darken-1 {
    background-color: #{nth($pink, 2)};
  }
  & {
    background-color: #{nth($pink, 3)};
  }
  &.lighten-1 {
    background-color: #{nth($pink, 4)};
  }
  &.lighten-2 {
    background-color: #{nth($pink, 5)};
  }
}

.pink-text {
  &.darken-2 {
    color: #{nth($pink, 1)};
  }
  &.darken-1 {
    color: #{nth($pink, 2)};
  }
  & {
    color: #{nth($pink, 3)};
  }
  &.lighten-1 {
    color: #{nth($pink, 4)};
  }
  &.lighten-2 {
    color: #{nth($pink, 5)};
  }
}

.grey {
  &.darken-3 {
    background-color: #{nth($grey, 1)};
  }
  &.darken-2 {
    background-color: #{nth($grey, 2)};
  }
  &.darken-1 {
    background-color: #{nth($grey, 3)};
  }
  & {
    background-color: #{nth($grey, 4)};
  }
  &.lighten-1 {
    background-color: #{nth($grey, 5)};
  }
  &.lighten-2 {
    background-color: #{nth($grey, 6)};
  }
  &.lighten-3 {
    background-color: #{nth($grey, 7)};
  }
}

.grey-text {
  &.darken-3 {
    color: #{nth($grey, 1)};
  }
  &.darken-2 {
    color: #{nth($grey, 2)};
  }
  &.darken-1 {
    color: #{nth($grey, 3)};
  }
  & {
    color: #{nth($grey, 4)};
  }
  &.lighten-1 {
    color: #{nth($grey, 5)};
  }
  &.lighten-2 {
    color: #{nth($grey, 6)};
  }
  &.lighten-3 {
    color: #{nth($grey, 7)};
  }
}
