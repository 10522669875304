//TODO: Replace with actual component styles
@import 'colors';

.company-list {
  max-height: 60vh;
  overflow: auto;
  padding: 0px 24px;
  &.has-error {
    max-height: calc(60vh - 87px);
  }
}
.login-error-message {
  margin-top: 24px;
  padding-left: 30px;
  padding-right: 16px;
}

h6.welcome-message {
  color: #506574;
  font-size: 24px;
  line-height: 30px;
  margin: 0 48px;
}

input::placeholder {
  color: #cfcfcf;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  font-style: italic;
}

.company-select-item.btn.btn-primary {
  position: relative;
  padding: 30px 24px;
  margin: 8px auto;
  width: 100%;
  border: 1px solid #506574 !important;
  background: transparent;
  color: #121212 !important;
  font-size: 14px;
  text-align: left;
}

.company-select-item.btn.btn-primary.active,
.company-select-item.btn.btn-primary:active,
.company-select-item.btn.btn-primary:focus,
.company-select-item.btn.btn-primary:hover {
  background: #e9f9f9 !important;
  border: 1px solid #506574 !important;
  box-shadow: none;
}

.company-select-item.btn.btn-primary:focus {
  border: 3px solid #00a3a5 !important;
  outline: none !important;
}

.company-select-item.btn.btn-primary.active:after {
  position: absolute;
  right: 24px;
  content: url('../assets/check-circle.svg');
}

.copyright-notice-login {
  font-size: 12px;
  position: relative;
  top: 16px;
  bottom: 16px;
  color: #6d899d;
  font-weight: 400;
  margin-left: 48px;
}

.login-banner {
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  color: white;
}

.login-subtitle {
  font-size: 18px;
  color: white;
}

.custom-form-login {
  form {
    position: relative;
    min-height: calc(100vh - 30px - 208px - 80px - 36px);
  }
  .form-control {
    background-color: #fafafa;
    border: 1px solid #9c9c9c;
  }
  .form-label {
    color: #506574;
  }
}

.bg-white {
  background-color: white !important;
}
.login-message-box {
  margin-top: 24px;
  padding-left: 30px;
  .warning-type {
    font-weight: 'bold';
    text-align: 'center';
    width: '100%';
  }
}

.desk-backto-login {
  padding-left: 30px;
  margin-top: 24px;

  .txt-link {
    display: inline-block;
    padding-left: 32px;
    position: relative;

    font: {
      size: 18px;
      weight: 600;
    }
    color: #{nth($teal, 3)};

    &::before {
      position: absolute;
      content: '';
      background: url('../assets/left_arrow_circle.svg') no-repeat;
      background-size: contain;
      height: 24px;
      width: 24px;
      left: 0;
      top: 2px;
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
