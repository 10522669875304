@import 'colors';
.att-list-member {
  height: 38px;
  padding: 10px 24px;
}
.cat {
  background: #{nth($blue-grey, 1)};
  color: #{nth($grey, 7)};
}

.file {
  text-align: center;
  height: auto;
  max-width: 150px;
  flex: 0 0 auto;
  width: 33%;
  user-select: none;
  cursor: pointer;

  & > div {
    margin: 12px 0;
    padding: 8px;
  }

  &:focus > div {
    background: #{nth($primary, 5)};
  }
}

.attachment {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 245px);
  //padding: 0 20px;

  .column-visiblity-table {
    border-collapse: separate;
    thead {
      tr {
        background: var(--table-header);
        color: var(--table-header-text-color);
      }
    }
    tbody {
      tr {
        background: var(--table-body);
      }
    }
  }
}
.upload-btn {
  border: 1px solid #{nth($teal, 3)};
  color: #{nth($teal, 3)};
  //border-radius: 4px;
  //margin-right: 5px;
  &:hover,
  &:focus {
    color: #{nth($grey, 7)};
    background-color: #{nth($teal, 3)};
    border-color: #{nth($teal, 3)};
    box-shadow: none;
  }
}
.btn-float-right {
  float: right;
}
.btn-width {
  width: 40px;
}

.attachment-name-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.column-visiblity-table {
  table-layout: fixed;
}

.attachment-table-upld-btn {
  padding-right: 0;
  cursor: pointer;
  width: 40px;
}

.attachment-table-type {
  width: 100px;
}
