@import './colors.scss';

.column-visibility-table-container {
  height: 90%;
  padding: 0;
  overflow-y: scroll;
}
.column-visiblity-table {
  border-spacing: 0 2px;
  width: 100%;
  color: var(--table-text-color);
  thead {
    position: sticky;
    top: 0;
    margin: 0 0 0 0;
    z-index: 2;
    tr {
      background: #{nth($light-teal, 4)};

      th {
        padding: 12px 12px;
      }
    }
  }
  tbody {
    //background: #{nth($light-grey, 1)};
    user-select: none;
    tr {
      padding: 12px 24px;
      height: 48px;
      border-bottom: 1px solid #{nth($grey, 3)};
      td {
        padding: 12px 12px;
        .editable-cell {
          //width: 100%;
          //height: 40px;
          border: 1px solid #dae2e7 !important;
          //border-radius: 4px;
          //padding: 8px;
        }
      }
    }
  }
}

.custom-search-column {
  input {
    width: calc(60% - 16px);
    background-color: var(--input-form-control);
  }
  input:focus {
    outline: none;
  }
}
.checkbox-column-visibility {
  text-align: center;
}
.tooltip-column-visibility {
  input {
    width: 30%;
  }
  input:focus {
    outline: none;
  }
}
.column-visibilty-icon {
  cursor: pointer;
}
.column-visibilty-span {
  display:inline-block;
  margin-right: 10px;
  float: right;
}