//TODO: Add all styles

body {
  font-family: Open Sans, sans-serif !important;
  font-style: normal;
  font-size: 14px;
  color: #404040;
}

[role='grid'] {
  &.BufferString * {
    font-family: 'Courier New';
    white-space: pre-wrap;
  }
}

body {
  font-family: Open Sans, sans-serif;
}

h6 {
  font-family: Open Sans, sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
}
