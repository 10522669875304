/**--------------Code starts here--------------------**/
@import './colors.scss';

.application-window {
  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
    gap: 20px; /* Gap between grid items */
    padding: 20px;
  }

  .card {
    /* width: 361.5px; */
    // height: 100px;
    border: 1px solid #{nth($teal, 3)};
    border-radius: 16px;
    padding: 20px;
    // transition: transform 0.3s, box-shadow 0.3s;

    &:focus {
      color: #{nth($grey, 7)};
      background-color: #{nth($teal, 3)};
      border-color: #{nth($teal, 3)};
      border-radius: 4px;
      box-shadow: none;
    }
  }

  .card:hover {
    // transform: translateY(-10px);
    // box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background: linear-gradient(rgba(0, 163, 165, 0.48), rgba(0, 163, 165, 1));
    //url("../assets/card-hover-image.png") no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    /* background-image: url("./assets/card-hover-image.png"); */
    /* background: #00a3a5; */
  }

  .card .title-group {
    display: flex;
    justify-content: space-around;
  }

  .card .title {
    width: 100%;
    height: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.6;
    color: #{nth($teal, 2)};
  }

  .card .date-field-box {
    width: 75px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    padding: 4px 12px 4px 12px;
    background: #{nth($light-teal, 3)};
  }

  .card .date-field-box .text {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.6;
    color: #{nth($grey, 2)};
  }

  .card .layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .card .layout .content-group {
    display: flex;
    gap: 16px;
    min-width: 75%;
  }

  .card .layout .headline {
    font-size: 36px;
    font-weight: 600;
    color: #{nth($grey, 2)};
    padding-right: 16px;
    border-right: solid 1px #{nth($teal, 4)};
  }

  .card .layout .group {
    display: flex;
    flex-direction: column;
  }

  .group:not(:last-child) {
    padding-right: 26px;
    border-right: solid 1px #{nth($light-teal, 2)};
  }

  .card .layout .ordervalue {
    font-size: 18px;
    font-weight: 600;
    color: #{nth($grey, 2)};
    line-height: 1.6;
  }

  .card .layout .subheading {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.6;
    color: #{nth($grey, 2)};
  }

  .card .layout .captions {
    font-size: 14px;
    font-weight: 600;
    color: #{nth($dark-mode, 6)};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 180px;
  }

  .card:hover .layout .icon {
    /* color: #00a3a5; */
    filter: brightness(0.3);
  }

  .card:hover .title,
  .card:hover .layout .headline,
  .card:hover .layout .icon,
  .card:hover .subheading,
  .card:hover .captions,
  .card:hover .ordervalue,
  .card:hover .path {
    color: #fff; /* Text color on hover */
  }

  .card:hover .layout .headline {
    border-right: solid 1px #fff;
  }
  .card:hover .svg {
    fill: #fff;
    color: #fff;
  }
}
