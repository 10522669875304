@import 'colors';

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-popper {
  z-index: 10;
}

.react-datepicker__header {
  background-color: #ffffff;
  border-bottom: 1px solid #aeaeae;
}
.react-datepicker__current-month {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.react-datepicker__day {
  color: #222730;
  font-weight: normal;
  font-size: 12px;
  line-height: 125%;
  padding: 7px 0;
  &:hover {
    border-radius: 50%;
  }
}
.react-datepicker__day--selected {
  color: #ffffff;
  border-radius: 50%;
  background-color: #{nth($teal, 2)};
  &:hover {
    border-radius: 50%;
    background-color: #{nth($teal, 2)};
  }
  &:focus {
    outline: none;
  }
  .react-datepicker__day--today {
    background-color: #{nth($teal, 2)};
  }
}
.react-datepicker__day-name {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #{nth($grey, 3)};
}
.react-datepicker__day-names {
  margin: 8px 0;
}
.react-datepicker__day--outside-month,
.react-datepicker__day--disabled {
  color: #{nth($grey, 3)};
}
.react-datepicker__input-container {
  input {
    width: 100%;
    //padding-right: calc(1.5em + 0.75rem);
    &.input-invalid {
      border: 1px solid $error !important;
      background: #f8bac6 !important;

    }
    &:focus {
      border: 2px solid #{nth($teal, 3)} !important;
      background: #{nth($light-teal, 3)};
      outline: none;
    }
  }
}

.react-datepicker__triangle {
  display: none;
}
.react-datepicker__day--keyboard-selected {
  background-color: #ffffff;
}
.datepicker-wrapper {
  position: relative;
  .calendar-icon {
    cursor: pointer;
    height: 14px;
    width: 14px;
    position: absolute;
    right: 5px;
    top: 6px;
  }
}
