@import './colors.scss';

:root,
html[data-theme='default'] {
  --background-color: #{nth($grey, 7)};
  --modal-header-color: #{nth($blue-grey, 1)};
  --text-color: #{nth($blue-grey, 1)};
  --desktop-color: #eff0f2;
  --tab-header: #{nth($blue-grey, 6)};
  --table-header: #{nth($blue-grey, 1)};
  --table-body: #{nth($light-grey, 1)};
  --table-text-color: #{nth($dark-mode, 5)};
  --table-row-selected-text-color: #{nth($dark-mode, 5)};
  --table-header-text-color: #{nth($grey, 7)};
  --table-resizer-color: #{nth($blue-grey, 3)};
  --table-row-selected-background: #{nth($light-teal, 3)};
  --table-row-not-selected-background: #{nth($light-teal, 4)};
  --table-row-not-selected-text-color: #{nth($dark-mode, 5)};
  --input-group-icon: #fafafa;
  --input-form-control: #fafafa;
  --input-form-control-border: #9c9c9c;
  --checkbox-background-color: #{nth($grey, 7)};
  --checkbox-background-checked: #{nth($light-teal, 3)};
  --checkbox-checkmark: url('../assets/checkmark.svg');
  --checkbox-border: #{nth($grey, 1)};
  --radio-button-dot: #{nth($blue-grey, 1)};
  --radio-button-background-color: #fafafa;
  --radio-button-background-checked: #{nth($light-teal, 3)};
  --primary-dark-svg: #{nth($blue-grey, 1)};
  --btn-outline-dark-bg: #{nth($blue-grey, 1)};
  --btn-outline-dark-color: #{nth($blue-grey, 1)};
  --modal-icon-color: #{nth($blue-grey, 1)};
  --tab-button: #eff0f2;
  --icon-dashboard: #{nth($blue-grey, 5)};
  --error-messages-subheading: #{nth($blue-grey, 1)};
  --error-messages-secondary-text: #{nth($blue-grey, 1)};
  --tab-header-text-color: #{nth($blue-grey, 5)};
  --modal-background: #{nth($grey, 7)};
  --modal-body-text-color: #{nth($blue-grey, 1)};
  --tab-border-bottom: #{nth($teal, 3)};
  --tab-dropdown-item-color: #{nth($blue-grey, 5)};
  --dropdown-background: #{nth($grey, 7)};
  --scrollbar-color: #{nth($blue-grey, 1)};
  --edit-input-field: #{nth($secondary-light, 3)};
  --calendar-body: #{nth($grey, 7)};
  --calendar-dates-disabled: #{nth($blue-grey, 6)};
  --background-header: #{nth($grey, 7)};
  --background-footer: #eff0f2;
  --context-menu-text: #{nth($blue-grey, 1)};
  --context-menu-hover: #{nth($light-teal, 4)};
  --context-menu-hover-text: #{nth($blue-grey, 1)};
  --dark-button-hover: #{nth($blue-grey, 1)};
  --dark-button-hover-bg: #{nth($blue-grey, 1)};
  --dark-button-hover-text: #{nth($grey, 7)};
  --disabled-input-background: #e8e8e8;
  --disabled-input-text: #404040;
  --disabled-input-border: #9c9c9c;
  --modal-opacity: 0.5;
  --modal-opacity-hover: 0.75;
  --modal-close-icon: #2c3740;
  --modal-header-bg: #{nth($grey, 7)};
  --client-settings-modal-header: #{nth($grey, 7)};
  --text-input-hover: #{nth($teal, 3)};
  --floating-toolbar: #2f3c46;
  --unselected-text: #2f3c46;
  --table-row-text: #9ea5ad;
  --disabled-checkbox-bg: #e8e8e8;
  --global-loader-text: #{nth($blue-grey, 3)};
  --search-fields-text: #404040;
  --search-fields-bg: #{nth($grey, 7)};
  --tabs-top-color: #{nth($blue-grey, 5)};
  --dashboard-cards-bg: #{nth($grey, 7)};
  --dashboard-cards-text: #404040;
  --table-active-tabs: #{nth($teal, 3)};
  --table-dropdown-button: url('../assets/kebab_vertical.svg');
  --table-dropdown-button-bg: #{nth($grey, 7)};
  --timepicker-background: #{nth($grey, 7)};
  --timepicker-label-text: #{nth($blue-grey, 1)};
  --error-report-input: #nth($grey, 7);
  --link-color: #1a0dab;
}
